import { Carousel, Image } from "antd";

function CarouselComponent() {
  return (
    <Carousel autoplay style={{ marginTop: "10px", marginBottom: "10px" }}>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007438/12_small_umbhri.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007438/10_small_oen6a6.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007436/9_small_pxmfaa.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007435/8_small_j7fl6d.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007435/7_small_jx0nzc.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007435/6_small_oub0sd.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007433/4_small_imhrdn.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007432/5_small_didvcf.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007432/3_small_wooywd.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007432/2_small_stlc57.jpg"
        />
      </div>
      <div>
        <Image
          width={400}
          height={400}
          src="https://res.cloudinary.com/dvqqgsm78/image/upload//l_image:upload:watermark_gfc8yh/c_scale,fl_relative,w_0.90/o_10/fl_layer_apply,g_center,x_0.03,y_0.04/v1733007432/1_small_mmhxgh.jpg"
        />
      </div>
    </Carousel>
  );
}

export default CarouselComponent;
