import "./App.css";

import { Layout } from "antd";
import HeaderComponent from "./components/header/header";
import FooterComponent from "./components/footer/footer";
import ContentComponent from "./components/content/content";

const { Header, Footer, Content } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  color: "#fff",
  paddingInline: 48,
  height: "64px",
  backgroundColor: "#0958d9",
};

const contentStyle: React.CSSProperties = {
  textAlign: "center",
  height: "100vh",
  color: "#fff",
  backgroundImage: "linear-gradient(to bottom, #0958d9, #4096ff, #0958d9)",
};

const footerStyle: React.CSSProperties = {
  height: "64px",
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#0958d9",
};

function App() {
  return (
    <Layout className="layout">
      <Header style={headerStyle}>
        <HeaderComponent />
      </Header>
      <Content className="content" style={contentStyle}>
        <ContentComponent />
      </Content>
      <Footer style={footerStyle}>
        <FooterComponent />
      </Footer>
    </Layout>
  );
}

export default App;
