import { CopyOutlined, MailOutlined } from "@ant-design/icons";
import { Button, message, Modal, Space, Tooltip, Typography } from "antd";
import { useState } from "react";

const { Text } = Typography;

function FooterComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [emailAddress] = useState("artworkforjoy@protonmail.com");

  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Text copied to clipboard!",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Failed to copy text",
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(emailAddress);
      success();
    } catch (err) {
      error();
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={showModal}>Order Artwork</Button>
      <Modal
        title="Thanks for your interest in my work!"
        open={isModalOpen}
        onOk={handleOk}
        closable={false}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        {contextHolder}
        <Space direction="vertical">
          <p>
            If you would like to send me an email, then I will contact you to
            discuss your request
          </p>
          <p>
            <Space>
              <MailOutlined style={{ color: "#1890ff" }} />
              <Text>{emailAddress}</Text>
              <Tooltip title="Copy to clipboard">
                <Button icon={<CopyOutlined />} onClick={handleCopy}>
                  Copy
                </Button>
              </Tooltip>
            </Space>
          </p>
        </Space>
      </Modal>
    </>
  );
}

export default FooterComponent;
