import { Space } from "antd";
import CarouselComponent from "./carousel/carousel";

function ContentComponent() {
  return (
    <>
      <CarouselComponent />
      <Space
        align="center"
        direction="vertical"
        style={{ marginLeft: "5vh", marginRight: "5vh" }}
      >
        <div>Welcome to "Artwork for Joy"!</div>
        <div>
          <p>
            We love art! Even if you're not the artist, there must be a painting
            that speaks to you. Perhaps you haven't found it yet. Maybe you're
            interested in history, cooking, cats, or cars? This doesn't matter;
            just contact us, and we will create a handcrafted work of art on any
            topic you choose.
          </p>
          <p>
            I’m thrilled to share my passion with you through my collection of
            original artworks crafted with pencil, pastel, and paint. Each piece
            is a labor of love, capturing the essence of creativity and emotion.
            Whether you’re looking for something bold and vibrant or subtle and
            serene, there’s a unique artwork here waiting to speak to you. Feel
            free to explore, get inspired, and perhaps find the perfect addition
            to your space. Thank you for supporting independent art and joining
            me on this colorful journey. Happy browsing and may you find as much
            joy in these creations as I did in making them!
          </p>
        </div>
      </Space>
    </>
  );
}

export default ContentComponent;
